import React from 'react';
import { Grid } from '@material-ui/core';
import i18n from '../../../../locale';
import Typography from '../../../../components/Typography';
import styles from './styles.module.sass';

export default () => (
  <Grid container className={styles.container}>
    <Typography variant='h2'
      weight='bold'
      className={styles.text}>
      {i18n('Empower your dealership today.')}
    </Typography>
  </Grid>
)