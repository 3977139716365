import React from 'react';
import i18n from '../../../../locale';
import styles from './styles.module.sass';

export default [{
  value: 'private',
  text: i18n('Private'),
  Icon: () => (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72.8 72.8"
      width='100%'
      height='100%'>
      <path className={styles.fill}
        d="M51.3 41.4a.8.8 0 0 0-.8-.7H22.3a.8.8 0 0 0-.8.7.8.8 0 0 0 .8.8h28.2a.8.8 0 0 0 .8-.8zM54.6 47.5h-6.9a1.6 1.6 0 0 0-1.5 1l-.9 2.1a1.6 1.6 0 0 0 1.5 2.3h6.9a1.5 1.5 0 0 0 1.4-1l1-2.1a1.8 1.8 0 0 0-.2-1.6 1.7 1.7 0 0 0-1.3-.7zm-.8 3.8h-7a.1.1 0 0 1-.1-.1l.9-2.1c0-.1 0-.1.1-.1h7zM25.9 48.5a1.8 1.8 0 0 0-1.5-1h-6.9a1.7 1.7 0 0 0-1.3.7 1.8 1.8 0 0 0-.2 1.6l.9 2.1a1.8 1.8 0 0 0 1.5 1h6.9a1.6 1.6 0 0 0 1.5-2.3zm-.5 2.8a.1.1 0 0 1-.1.1h-7l-.9-2.1a.1.1 0 0 1 .1-.1h6.9a.1.1 0 0 1 .1.1l.9 2.1z"/>
      <path className={styles.fill}
        d="M63.6 36.3h-3a4.7 4.7 0 0 0-2.9.8l-.9.5c-.5-.8-1-1.7-1.6-2.5-3.6-5.5-6-6.9-17-6.9h-3.7c-11 0-13.3 1.4-16.9 6.9l-1.7 2.5-.8-.5a5.1 5.1 0 0 0-2.9-.8h-3a1.3 1.3 0 0 0-1.4 1.3A1.4 1.4 0 0 0 9.2 39h3a2.8 2.8 0 0 1 1.5.4l.4.2-1.7 1.3a7 7 0 0 0-2.8 5.6v8.7a7.9 7.9 0 0 0 3.6 6.6v1.7a3.3 3.3 0 0 0 1 2.5 3.3 3.3 0 0 0 2.4 1h5.6a3.5 3.5 0 0 0 3.5-3.5V63h21.4v.5a3.5 3.5 0 0 0 3.5 3.5h5.5a3.3 3.3 0 0 0 2.5-1 3.7 3.7 0 0 0 1-2.5v-1.7a7.9 7.9 0 0 0 3.6-6.6v-8.7a7.3 7.3 0 0 0-2.8-5.6l-1.7-1.2.4-.3a2.6 2.6 0 0 1 1.5-.4h3a1.4 1.4 0 0 0 1.4-1.4 1.3 1.3 0 0 0-1.4-1.3zm-6.7 24.6v2.7a.9.9 0 0 1-.8.7h-5.5a.8.8 0 0 1-.7-.8v-1.7h3.5a.8.8 0 0 0 .7-.8.7.7 0 0 0-.7-.7h-6.6l-2-3.9a3.5 3.5 0 0 0-3.1-1.8H30.8a3.5 3.5 0 0 0-3.1 2l-1.8 3.7h-6.4a.7.7 0 0 0-.8.7.8.8 0 0 0 .8.8h3.4v1.7a.8.8 0 0 1-.7.8h-5.5a.9.9 0 0 1-.8-.8v-2.6a1.3 1.3 0 0 0-.7-1.2 5 5 0 0 1-2.8-4.5v-8.7a4.4 4.4 0 0 1 1.6-3.4l2.4-1.7a3.2 3.2 0 0 0 1.1-1.2l2.4-3.6C22.7 32.2 24 31 34.5 31h3.8c10.5 0 11.8 1.2 14.6 5.6.8 1.2 1.6 2.4 2.3 3.6a4.6 4.6 0 0 0 1.2 1.2l2.4 1.7a4.4 4.4 0 0 1 1.6 3.4v8.7a5 5 0 0 1-2.8 4.5 1.3 1.3 0 0 0-.7 1.2zm-29.3-.6l1.5-3.1a1.8 1.8 0 0 1 1.7-1.1h10.9a2 2 0 0 1 1.8 1.1l1.6 3.1z"/>
    </svg>
  )
}, {
  value: 'business',
  text: i18n('Business'),
  Icon: () => (
    <svg xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 72.8 72.8"
      width='100%'
      height='100%'>
      <path className={styles.fill}
        d="M60.6 38.5a1.4 1.4 0 0 0-1.8.7l-1 2h-1.9v-7.3a38.3 38.3 0 0 0-.3-4.7l-1.3-7.7A5.4 5.4 0 0 0 49 17H23.8a5.3 5.3 0 0 0-5.3 4.5l-1.2 7.3a34.3 34.3 0 0 0-.4 5.6v6.8H15l-1-2a1.4 1.4 0 0 0-1.8-.7 1.4 1.4 0 0 0-.6 1.8l1.3 2.9a1.4 1.4 0 0 0 1.2.7h2.8v11.7a10.6 10.6 0 0 0 .5 3.1l.7 1.7a4.1 4.1 0 0 0 1.7 2.2v1.6a2.8 2.8 0 0 0 2.8 2.8h5.6a2.7 2.7 0 0 0 2.8-2.8v-.7h10.8v.7a2.7 2.7 0 0 0 2.8 2.8h5.6a2.8 2.8 0 0 0 2.8-2.8v-1.6a4.7 4.7 0 0 0 1.7-2.2l.7-1.7a10.6 10.6 0 0 0 .5-3.1V43.9h2.7a1.6 1.6 0 0 0 1.3-.7l1.3-2.9a1.5 1.5 0 0 0-.6-1.8zm-7.4 17.1a7.1 7.1 0 0 1-.4 2.1l-.7 1.7a2 2 0 0 1-1.4 1.3.8.8 0 0 0-.5.7v2.9h-5.7v-2h2.1a.8.8 0 0 0 .7-.8.7.7 0 0 0-.7-.7H26.2a.7.7 0 0 0-.7.7.8.8 0 0 0 .7.8h2.1v2h-5.7v-2.9a.7.7 0 0 0-.6-.7 2.5 2.5 0 0 1-1.4-1.3l-.6-1.7a4.9 4.9 0 0 1-.4-2.1V34.4a45.5 45.5 0 0 1 .4-5.2l1.2-7.2a2.7 2.7 0 0 1 2.6-2.3H49a2.7 2.7 0 0 1 2.6 2.3l1.2 7.7a19.9 19.9 0 0 1 .4 4.2z"/>
      <path className={styles.fill}
        d="M22.8 43.3H50a2.1 2.1 0 0 0 1.3-.5 2.3 2.3 0 0 0 .4-1.4l-1.1-12.3a1.7 1.7 0 0 0-1.7-1.6h-25a1.7 1.7 0 0 0-1.7 1.6l-1.1 12.3a1.8 1.8 0 0 0 .4 1.4 2.1 2.1 0 0 0 1.3.5zm.9-14.1l.2-.2h25a.2.2 0 0 1 .2.2l1.1 12.4v.2H22.6v-.2zM43.4 48h-14a1.4 1.4 0 0 0-1.2.6 1.9 1.9 0 0 0-.1 1.8l1.2 3a3 3 0 0 0 2.7 2h8.9a3.2 3.2 0 0 0 2.8-2.1l1.1-3a2.1 2.1 0 0 0-.2-1.7 1.4 1.4 0 0 0-1.2-.6zm0 1.8l-1.2 2.9a1.5 1.5 0 0 1-1.3 1.2H32a1.6 1.6 0 0 1-1.3-1.1l-1.3-3v-.3h13.9c.1 0 .1.1.1.3zM24.1 48.7a2.9 2.9 0 0 0-3 3 3 3 0 0 0 3 3 3.1 3.1 0 0 0 3-3 3 3 0 0 0-3-3zm0 4.5a1.5 1.5 0 0 1-1.5-1.5 1.5 1.5 0 0 1 3 0 1.5 1.5 0 0 1-1.5 1.5zM48.7 48.7a2.9 2.9 0 0 0-3 3 3 3 0 0 0 6 0 2.9 2.9 0 0 0-3-3zm0 4.5a1.5 1.5 0 0 1-1.5-1.5 1.5 1.5 0 0 1 3 0 1.5 1.5 0 0 1-1.5 1.5z"/>
    </svg>
  )
}]