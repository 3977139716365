import React from 'react';
import Content from '../views/Dealership/Price';

export default ({location}) => <Content location={location} seo={{
  title: 'What does the car dealership management solution cost?',
  lang: 'en',
  description: 'Get the price for the car dealership management solution by filling out the quotation form.',
  meta: [{
    name: 'keywords',
    content: 'Dealership solution'
  }]
}}/>